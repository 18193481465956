import * as React from "react"

import Layout from "../components/layout"

const Impressum = () => (
  <Layout>
    <div className="m-32">
      <h1 className="text-3xl">Impressum</h1>
      <strong>Angaben gemäß § 5 TMG:</strong>
      <strong className="mt-4 block">Lars Natus</strong>
      <p>Türkismühlerstraße 19</p>
      <p>66113 Saarbrücken</p>
      <p>hello [at] larsnatus.com</p>
      <p>USt-IdNr.: DE349764156</p>
      <p className="mt-4">
        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: http://ec.europa.eu/consumers/odr Meine E-Mail-Adresse finden Sie oben im Impressum.
        Ich bin nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>
     </div>
  </Layout>
)

export default Impressum
